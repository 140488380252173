<template>
  <div id="contact" class="contact">
    <h3>联系我们</h3>
    <p>地址：International City D04 S09,Dubai,UAE</p>
    <p>电话：+971 0522532578</p>
    <!-- 地图iframe或其他内容 -->

    <div class="map-container">
      <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD0Vl0rT0Txh7LCZujqj3KDAFRw-zHQuQw&q=椒点川菜+Dubai"
          frameborder="0" style="border:0;" allowfullscreen="">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactSection',
};
</script>

<style scoped>
.contact {
  background: #f4f4f4;
  padding: 20px;
  text-align: center;
  margin: 20px auto;
  max-width: 1460px;
}

.map-container iframe {
  width: 100%; /* 使用100%宽度 */
  height: auto; /* 自动高度 */
  aspect-ratio: 16 / 9; /* 维持宽高比 */
  border: none; /* 移除边框 */
}

@media (max-width: 768px) {
  .contact {
    padding: 10px; /* 在较小屏幕上减小内边距 */
    margin: 10px auto;
  }
  .map-container {
    margin-top: 10px; /* 在较小屏幕上减小间距 */
  }
}
</style>
