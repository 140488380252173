<template>
  <div class="story">
    <h3>属于江湖菜的情怀</h3>
    <p>椒点川菜 —— 重庆江湖菜的独特魅力


      深藏于重庆小巷的味蕾传奇，我们的“椒点川菜”为您呈现最纯正的重庆江湖菜风味。每一道菜都融合了重庆的热情与火辣，从每一片鲜嫩的牛肉到每一滴香辣的红油，都浓缩了重庆的独特文化和历史。


      在这里，您可以品尝到经典的重庆辣子鸡、麻辣的金汤酸菜鱼，还有那无与伦比的干锅系列。我们严选食材，传统制作，保证每一口都能让您体验到重庆江湖的真实味道。


      不仅如此，我们还精选了众多川菜经典，无论您是喜欢家常的麻婆豆腐，还是偏爱特色的口味姜爆鸭，都能在这里找到满足。


      椒点川菜，不只是一家餐厅，更是一个让您与重庆江湖零距离接触的地方。快来加入我们，体验真正的川味风情！</p>
  </div>
</template>

<script>
export default {
  name: 'StorySection',
};
</script>

<style scoped>
.story {
  padding: 20px;
  background: #f4f4f4;
  text-align: center;

  max-width: 1460px; /* 调整最大宽度以适应你的设计 */
  margin: 0 auto; /* 居中显示 */

}
</style>
