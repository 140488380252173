// src/main.js
import {createApp} from 'vue';
import App from './App.vue';
import router from './router'; // 引入router
import ElementPlus from 'element-plus';
import {ElNotification} from 'element-plus';
import 'element-plus/dist/index.css';
import service from "./utils/request";
import storage from "@/models/storage";
import '@fortawesome/fontawesome-free/css/all.css';


const app = createApp(App);
app.use(router); // 使用router
app.use(ElementPlus);
app.mount('#app');


service.interceptors.request.use(
    config => {
        // 定义一个白名单数组
        const whiteList = ['/captcha', '/user/create', '/sendRegisterMail'];
        // 获取当前路由路径，需要根据实际项目结构调整获取方式
        const path = config.url


        // 获取用户信息
        const loginUser = storage.get('loginUser');

        // 如果当前路径不在白名单中，且用户已经登录（token存在），则为请求自动添加token
        if (whiteList.indexOf(path) === -1 && loginUser && loginUser.token) {
            config.headers['x-token'] = loginUser.token;
        }

        return config;
    },
    error => {
        // 请求错误处理
        Promise.reject(error);
    }
);


router.beforeEach((to, from, next) => {
    const loginUser = storage.get('loginUser');
    window.console.log(loginUser);

    // 定义一个不需要token就能访问的路径数组
    const noTokenNeededRoutes = ['/login', '/register', '/404', "/", "/help"];

    // 检查即将访问的路径是否在不需要token的路径数组中，或者用户是否已经登录且拥有有效的token
    if (noTokenNeededRoutes.includes(to.path) || (loginUser && loginUser.token)) {
        next(); // 直接放行
    } else {
        next('/login'); // 否则重定向到登录页面
    }
});


service.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        return response.data;
    },
    async (error) => {
        // 对响应错误做点什么
        if (error.response) {
            const errMsg = error.response.data?.message || error.response.statusText;
            const statusCode = error.response.status;

            switch (statusCode) {
                case 400:
                    ElNotification({
                        title: '错误',
                        message: `400 BAD REQUEST: ${errMsg}`,
                        type: 'error',
                    });
                    break;
                case 401:
                    // 清除用户登录信息
                    storage.remove("loginUser");
                    // 显示登录失效的提示
                    ElNotification({
                        title: '登录失效',
                        message: '您的登录状态已失效，请重新登录。',
                        type: 'error',
                        duration: 5000 // 这里的duration是提示框显示的时间，单位为毫秒
                    });
                    // 延迟一段时间后重定向到登录页面，给用户一个阅读提示信息的机会
                    setTimeout(() => {
                        handleRouterPush('/login');
                    }, 3000); // 延迟3秒跳转
                    break;
                case 404:
                    // 页面不存在的情况
                    await handleRouterPush('/404');
                    break;
                case 500:
                case 501:
                case 502:
                    // 服务器错误处理
                    ElNotification({
                        title: '服务器错误',
                        message: `服务器错误: ${errMsg}`,
                        type: 'error',
                    });
                    break;
                default:
                    // 处理其他未捕获的错误状态码
                    ElNotification({
                        title: '错误',
                        message: `发生错误: ${errMsg}`,
                        type: 'error',
                    });
            }

            return Promise.reject(new Error(`ERROR ${statusCode}: ${errMsg}`));
        } else if (error.request) {
            // 请求被发出，但没有收到回应
            ElNotification({
                title: '网络问题',
                message: '网络请求无响应',
                type: 'error',
            });
            return Promise.reject(new Error('网络请求无响应'));
        } else {
            // 发生了其他问题
            ElNotification({
                title: '请求错误',
                message: `请求错误: ${error.message}`,
                type: 'error',
            });
            return Promise.reject(new Error(`请求错误: ${error.message}`));
        }
    }
);


function handleRouterPush(path) {
    router.push({path}).catch(err => {
        if (err.name !== 'NavigationDuplicated' && err.name !== 'CanceledError') {
            console.error('路由跳转错误', err);
        }
    });
}