<script setup>
</script>

<template>
    <div style="background-color: orange">
      <el-menu
          background-color="#409eff"
          text-color="#fff"
          active-text-color="#ffd04b"
          class="navbar"
      >
<!--              <el-menu-item index="1"><a href="#dishes">首页</a></el-menu-item>-->

        <!--      <el-menu-item index="2"><a href="#dishes">菜品展示</a></el-menu-item>-->
        <el-menu-item index="3"><a href="#contact">联系我们</a></el-menu-item>
        <el-menu-item index="4">
          <router-link to="/help">使用说明</router-link>
        </el-menu-item>
        <el-menu-item v-if="!isLogin" index="4" class="menu-item-login">
          <router-link to="/login" class="login-link">
            <el-tooltip content="登录/注册">
              <el-image
                  src="https://img.ixintu.com/download/jpg/20201130/a5a797801c5e0abd58d80d2182081608_512_512.jpg!con"
                  class="avatar"></el-image>
            </el-tooltip>
          </router-link>
        </el-menu-item>
        <el-menu-item v-else index="4" class="menu-item-login">
          <el-popover
              trigger="click"
              placement="bottom"
              width="150"
              popper-class="profile-popper"
          >

            <template v-slot:reference>
              <el-image
                  :src="logo"
                  class="avatarLogin"
                  @click="showProfileDialog"
              ></el-image>




<!--              <div class="loginContent"  @click="showProfileDialog">-->
<!--                {{this.nick_name}}-->
<!--              </div>-->

            </template>
            <div>
              <el-menu
                  mode="vertical"
                  default-active="1"
              >
                <el-menu-item v-if="isAdmin" index="1" @click="handleAdminPage">
                  <i class="el-icon-document"></i> 管理后台
                </el-menu-item>

                <el-menu-item v-else index="1" @click="handleClickMenuItem"  >
                  <i class="el-icon-document"></i> 修改资料
                </el-menu-item>

                <el-menu-item index="2" @click="toggleCouponList" >
                  <i class="el-icon-ticket"></i> 我的优惠券
                </el-menu-item>
                <el-menu-item index="3" @click="toggleInvitedPage">
                  <i class="el-icon-switch-button"></i> 邀请有礼
                </el-menu-item>
                <el-menu-item index="4" @click="handleClickMenuItem">
                  <i class="el-icon-document"></i>意见与反馈
                </el-menu-item>
                <el-menu-item index="5" @click="handleLogout">
                  <i class="el-icon-switch-button"></i> 退出登录
                </el-menu-item>
              </el-menu>
            </div>
          </el-popover>
        </el-menu-item>
      </el-menu>


      <!-- 我的优惠券抽屉   -->
      <el-drawer
          title="我的优惠券"
          v-model="drawer"
          size="30%"
          :before-close="handleDrawerClose"
          :style="{ zIndex: 1000 }"
      >
        <!-- 通用的领取优惠券按钮和规则说明 -->
        <div class="coupon-actions">
          <el-button type="success" size="small" @click="getMonthAllCoupon" :loading="couponButtonLoading">领取优惠券
          </el-button>
          <p class="coupon-description">领取规则：每人每月限领一次，使用期限为领取后的3个月内。</p>
        </div>

        <!-- 以下为优惠券列表的内容，您可以根据实际需求进行修改和填充数据 -->
        <div>
          <el-card
              class="coupon-card"
              shadow="hover"
              v-for="coupon in coupons"
              :key="coupon.id"
          >
            <h4 style="font-size: 16px;"> <!-- 调整为所需的字体大小 -->
              {{ coupon.coupon_type }}
            </h4>
            <p style="font-size: 14px;">优惠券码：{{ coupon.coupon_code }}</p>
            <!-- 判断优惠券类型 -->
            <p v-if="coupon.coupon_type === '折扣券'" style="font-size: 14px;">
              折扣：<span style="font-weight: bold; color: red;">{{ coupon.discount_rate }}折</span>
            </p>
            <p v-else-if="coupon.coupon_type === '现金优惠券'" style="font-size: 14px;">
              优惠金额：<span style="font-weight: bold; color: red;">{{ coupon.coupon_amount }}AED</span>
            </p>
            <p style="font-size: 14px;">有效期至：{{ formatDate(coupon.expire_date) }}</p>
            <p style="font-size: 14px;">{{ coupon.desc }}</p>

          </el-card>
        </div>
      </el-drawer>

      <!-- 我的积分抽屉   -->
      <el-drawer title="邀请中心" v-model="invDrawer" size="30%" :before-close="handleDrawerClose" :style="{ zIndex: 1000 }">

        <div class="invite-center">
          <div class="invite-item">
            <div class="invite-input-container">
              <input type="text" class="invite-input" :value="userInfo.invite_url" disabled>
            </div>
            <button class="invite-button" @click="copyInviteLink">复制</button>
          </div>
          <div class="invite-item">
            <div class="invite-input-container">
              <input type="text" class="invite-input" :value="userInfo.invite_code" disabled>
            </div>
            <button class="invite-button" @click="copyInviteCode">复制</button>
          </div>

          <el-card class="invite-statistics-card">
            <el-row :gutter="20">
              <el-col :span="8">
                <h5>我的邀请人数</h5>
                <p class="invite-statistics-number">{{ userInfo.invited_user_count }}</p>
              </el-col>
              <el-col :span="8">
                <h5>累计获得积分</h5>
                <p class="invite-statistics-number">{{ userInfo.total_point }}</p>
              </el-col>
              <el-col :span="8">
                <h5>当前剩余积分</h5>
                <p class="invite-statistics-number">{{ userInfo.remaining_point }}</p>
              </el-col>
            </el-row>
          </el-card>

          <el-card class="invite-statistics-card">
            <el-row class="invite-info-row" type="flex" justify="center">
              <el-col :span="24">
                <h4 class="invite-info-title">邀请注册，畅享积分返还</h4>
                <p class="invite-info-text">
                  分享您的专属邀请码或链接，让更多人加入我们的平台，注册并消费，即可获得丰厚的积分返还！积分可在店里当现金消费</p>
                <p class="invite-info-text">
                  邀请越多，奖励越丰厚！让您的邀请码成为友谊的桥梁，让积分返还成为您人脉的见证。不仅能让朋友们享受折扣，还能为自己带来实实在在的收益。</p>
                <p class="invite-info-text">
                  别再犹豫了，立即分享您的邀请码，邀请更多的朋友加入，一起分享这份独特的喜悦和收益吧！
                </p>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-drawer>


    </div>
</template>


<script>
import storage from "@/models/storage";
import {couponList, monthlyCouponGet} from "@/api/coupon";
import {ElNotification} from 'element-plus';
import {userDetail} from "@/api/user"
import logo from "@/assets/点我2.jpg"

export default {
  name: 'AppNavbar',
  data() {
    return {
      logo:logo,
      isLogin: false,
      drawer: false, // 控制优惠券列表抽屉的显示
      invDrawer: false,//邀请中心抽屉的显示
      coupons: [],
      couponButtonLoading: false,
      userId: "",
      email: "",
      nick_name:"",
      isAdmin: false,

      userInfo: {
        "birthday": "",
        "email": "",
        "gender": "",
        "id": "",
        "invite_code": "",
        "invite_url": "",
        "invited_user_count": "",
        "remaining_point": "",
        "total_point": ""
      },

    };
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString().split('T')[0];
    },
    getMonthAllCoupon() {
      this.couponButtonLoading = true
      monthlyCouponGet(this.userId).then((resp) => {
        if (resp.code === 0) {
          ElNotification({
            message: '领取优惠券成功',
            type: 'success',
          });
          // 关闭抽屉
          this.drawer = false;
          this.couponButtonLoading = false
        } else {
          this.couponButtonLoading = false
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }

      }).catch(() => {
        this.couponButtonLoading = false
      })


    },
    handleLogout() {
      storage.remove('loginUser');
      this.isLogin = false;
      this.drawer = false; // 退出时关闭抽屉
    },
    handleClickMenuItem() {
      ElNotification({
        message: '敬请期待',
        type: 'success',
      });

    },

    toggleCouponList() {
      this.drawer = !this.drawer; // 切换优惠券列表抽屉的显示状态
      couponList(this.userId).then((resp) => {
        if (resp.code === 0) {
          this.coupons = resp.data
          console.log(this.coupons)
        } else {
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }
      })
    },

    // 邀请中心页面
    toggleInvitedPage() {
      this.invDrawer = !this.invDrawer;
      userDetail(this.email).then((resp) => {
        if (resp.code === 0) {
          this.userInfo = resp.data
        } else {
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }
      })
    },

    handleAdminPage() {
      this.$router.push('/admin');
    },
    showProfileDialog() {
      console.log('点击了头像');
      // 显示个人资料对话框的逻辑
    },
    handleDrawerClose() {
      this.drawer = false; // 处理抽屉关闭事件
      this.invDrawer = false
    },

    copyToClipboard(text) {
      // 创建一个临时的textarea元素
      const textArea = document.createElement('textarea');

      // 设置textarea的值为要复制的文本
      textArea.value = text;

      // 将textarea添加到文档中
      document.body.appendChild(textArea);

      // 选中textarea中的文本
      textArea.select();

      try {
        // 执行复制命令
        const successful = document.execCommand('copy');
        const msg = successful ? '成功复制到剪贴板' : '无法复制到剪贴板';
        this.$message({
          message: msg,
          type: successful ? 'success' : 'error'
        });
      } catch (err) {
        console.error('复制失败:', err);
        this.$message({
          message: '复制失败',
          type: 'error'
        });
      }

      // 移除textarea元素
      document.body.removeChild(textArea);
    },
    copyInviteLink() {
      // 调用copyToClipboard方法并传入inviteLink
      this.copyToClipboard(this.userInfo.invite_url);
    },
    copyInviteCode() {
      this.copyToClipboard(this.userInfo.invite_code)
    },

  },
  mounted() {
    if (storage.get("loginUser") != null) {
      this.isLogin = true
      this.userId = storage.get("loginUser").user_id
      this.email = storage.get("loginUser").email
      this.nick_name=storage.get("loginUser").nick_name
      if (storage.get("loginUser").role === "2" || storage.get("loginUser").role === 2) {
        this.isAdmin = true
      }
    }
  }
};
</script>

<style scoped>


.coupon-actions {
  padding: 20px 0; /* 操作区域的内边距 */
  text-align: center; /* 居中按钮和说明文字 */
}

.coupon-description {
  margin-top: 10px; /* 规则说明与按钮间距 */
  color: #666; /* 文字颜色 */
  font-size: 10px; /* 文字大小 */
}

.coupon-item h4 {
  /* 标题样式，确保图标和标题对齐 */
  display: flex;
  align-items: center;
  justify-content: space-between; /* 优化对齐 */
}

/* 调整图标的样式 */
.coupon-item h4 {
  font-size: 1.5em; /* 增加图标大小 */
  margin: 0 5px; /* 增加左右边距 */
  color: #409EFF; /* 调整图标颜色 */
}

.coupon-card {
  margin-bottom: 20px; /* 卡片之间的间距 */
  /* 卡片样式可以根据需要进行更多自定义 */
  background-color: #f4f4f4
}


.avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarLogin{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 127, 80, 1);
  padding-right: 20px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; /* 确保导航栏在其他内容之上 */
}

.menu-item-login {
  margin-left: auto;
}

.login-link {
  color: #fff;
}

.profile-popper .el-menu {
  border: none; /* 去除菜单边框 */
  box-shadow: none; /* 去除默认阴影 */
}

.el-menu-item a {
  text-decoration: none; /* 移除链接的下划线 */
  color: white; /* 设置链接文字颜色为白色 */
}

/* 添加的优惠券列表抽屉样式 */
.coupon-item {
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.coupon-item h4 {
  margin: 0;
  color: #333;
}

.coupon-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}


/* 邀请样式 */

.invite-container {
  display: flex;
  flex-direction: column;
}

.invite-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.invite-label {
  margin-right: 10px;
}

.invite-input-container {
  flex: 1; /* 输入框占据剩余空间 */
  position: relative; /* 用于定位复制按钮（如果需要） */
}

.invite-input {
  width: 100%; /* 输入框宽度占满容器 */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* 包含padding和border在width内 */
  background-color: #f5f7fa;
}

.invite-button {
  padding: 8px 16px;
  margin-left: 10px; /* 与输入框之间的间距 */
  border: none;
  border-radius: 4px;
  background-color: #409EFF;
  color: #fff;
  cursor: pointer;
}

/* 可选：为不同的输入框添加不同的背景色 */

.invite-statistics-card {
  border-radius: 4px;
  background-color: #f4f4f4;
  overflow: hidden;
  margin-bottom: 20px;
}

.invite-statistics-number {
  font-size: 20px;
  font-weight: bold;
  color: orange;
  margin-top: 4px;
}


.invite-info-row {
  margin-top: 20px; /* 添加上边距以分隔卡片和文案 */
}

.invite-info-title {
  //font-weight: bold; /* 标题加粗 */
  text-align: center; /* 标题居中 */
}

.invite-info-text {
  color: #666; /* 文本颜色 */
  text-align: justify; /* 文本两端对齐 */
  font-size: 14px; /* 设置字体大小 */
}

/* 移动端样式调整 */
@media (max-width: 768px) {
  :deep(.el-drawer) {
    width: 100% !important; /* 在移动设备上抽屉宽度为100% */
  }

  /* 针对优惠券项目的样式调整 */
  :deep(.coupon-item) {
    display: block;
    margin-bottom: 10px;
    padding: 10px;
  }

  :deep(.coupon-item h4) {
    font-size: 16px;
    margin-bottom: 5px;
  }

  :deep(.coupon-item p) {
    font-size: 14px;
  }

  :deep(.coupon-item .el-button) {
    margin-top: 10px;
  }

  :deep(.el-drawer__header) {
    font-size: 18px;
  }
}

.el-drawer {
  z-index: 9999 !important; /* 使用一个足够高的值，确保它显示在顶部 */
}

/* 更大屏幕设备的样式 */
@media (min-width: 769px) {
  :deep(.el-drawer) {
    width: 25% !important; /* 在大屏设备上抽屉宽度为屏幕的一半 */
  }
}
</style>

