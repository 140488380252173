<template>
  <div>
    <app-navbar />
    <header-image />
    <dishes-display />
    <story-section />
    <contact-section />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import HeaderImage from '@/components/HeaderImage.vue';
import DishesDisplay from '@/components/DishesDisplay.vue';
import StorySection from '@/components/StorySection.vue';
import ContactSection from '@/components/ContactSection.vue';

export default {
  name: 'HomePage',
  components: {
    AppNavbar,
    HeaderImage,
    DishesDisplay,
    StorySection,
    ContactSection,
  },
};
</script>

<style scoped>

</style>
