<template>
  <div class="usage-instructions">
    <a href="/" style="font-size: small">返回首页</a>

    <h2>一、优惠券介绍与使用</h2>
    <p>优惠券是我们为会员提供的虚拟券码，可用于店内消费打折。</p>
    <p>优惠券分为现金优惠券和折扣券，未来还将推出更多类型。</p>
    <h3>如何获取优惠券？</h3>
    <ul>
      <li>新用户注册即可获得一张88折优惠券。</li>
      <li>每月，每位用户都可领取一张随机优惠券。</li>
      <li>我们还会不定期发放更多优惠券。</li>
    </ul>
    <h3>优惠券使用规则</h3>
    <ul>
      <li>优惠券有效期为领取之日的2小时后至3个月内。所以刚领取完优惠券需要等2小时才能使用</li>
      <li>账户优惠券数量达到3张后，将无法继续领取。必须进行消费或者过期后才能继续领取</li>
      <li>结账时出示优惠券码，并等待确认邮件,请保持您的邮件一直处于可用状态</li>
    </ul>

    <h2>二、积分制度</h2>
    <p>积分可在店内当作现金使用，1积分等于1AED。</p>
    <h3>如何获取积分？</h3>
    <p>目前获取积分的唯一方式为推荐好友注册成功且第一次使用赠送的优惠券消费后，您将获得消费金额的5%的返现。</p>
    <p>返现以积分形式发放到您账户中。可登录后查看</p>
    <h3>如何使用积分？</h3>
    <p>结账时告知店员您的邮箱和想使用的积分数量，并等待确认邮件。请保持您的邮件一直处于可用状态</p>

    <h2>三、资料修改</h2>
    <p>登录后可在“修改资料”中设置密码、生日和性别等信息。</p>
    <p>我们会根据信息不定期发放优惠券或积分奖励。例如生日金，38妇女节优惠券等，敬请期待</p>
    <p>生日和性别一旦设置，将无法再次修改。</p>

    <h2>四、意见与反馈</h2>
    <p>
      亲爱的用户们，我们深知每一位顾客的宝贵意见都是我们不断前进的动力。在这里，我们热切地期待着您的声音，不论是点赞还是吐槽，都将成为我们改进服务的指南针。</p>
    <p>
      您的每一个建议，都像是照亮我们前行道路的明灯，让我们能够更精准地把握您的需求，提供更贴心、更完善的服务。所以，请不要吝啬您的宝贵意见，大胆地说出您的想法，让我们共同携手，打造更美好的使用体验。</p>
  </div>
</template>

<script>
export default {
  name: 'UsageInstructions',
  // 这里可以添加组件的data、methods等
}
</script>

<style scoped>
.usage-instructions {
  /* 这里添加您的样式 */
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  font-family: 'Arial', sans-serif; /* 更改字体族 */
  font-size: 20px; /* 更改字体大小 */
  font-weight: 600; /* 更改字体粗细 */
  color: #333; /* 更改字体颜色 */
  line-height: 1.3; /* 更改行高 */
  margin-bottom: 20px; /* 调整下边距 */
}

p ,ul{
  font-family: 'Microsoft YaHei', sans-serif; /* 使用微软雅黑字体，适合中文 */
  font-size: 16px; /* 更改字体大小 */
  color: #666; /* 更改字体颜色 */
  line-height: 1.6; /* 更改行高 */
  margin-bottom: 15px; /* 调整下边距 */
}
</style>