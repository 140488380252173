<template>
  <div class="dishes-container">
    <h3 class="dishes-title">经典菜品展示</h3>
    <el-row :gutter="20">
      <el-col :span="24" :md="8" :lg="6" v-for="dish in dishes" :key="dish.id" class="dish-item">
        <el-card>
          <div class="image-container">
            <img :src="dish.image" alt="dish.name"/>
          </div>
          <div class="card-content">
            <span>{{ dish.name }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'AppDishesDisplay',
  data() {
    return {
      dishes: [
        { id: 1, name: '重庆烤鱼', image: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fss2.meipian.me%2Fusers%2F9455029%2F5a98c10abdfa4d6eb255d15f1fe80f80.jpg%3Fmeipian-raw%2Fbucket%2Fivwen%2Fkey%2FdXNlcnMvOTQ1NTAyOS81YTk4YzEwYWJkZmE0ZDZlYjI1NWQxNWYxZmU4MGY4MC5qcGc%3D%2Fsign%2F1d04759c687d6d402cb09712ed0ffed7.jpg&refer=http%3A%2F%2Fss2.meipian.me&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1714799260&t=c48909e35641fd4d692ffd77844d693e'},
        { id: 2, name: '来凤鱼', image: "https://so5.360tres.com/t0127a51a8fc82d385a.jpg" },
        { id: 3, name: '麻辣小龙虾', image: "https://img1.baidu.com/it/u=288715624,86257404&fm=253&fmt=auto&app=138&f=JPEG?w=770&h=428" },
        { id: 4, name: '麻辣水煮鱼', image: "https://pic.rmb.bdstatic.com/3475417f602cc04db2d0d564c7606a2a.jpeg@wm_2,t_55m+5a625Y+3L+WygeaciOWFpemUhQ==,fc_ffffff,ff_U2ltSGVp,sz_41,x_26,y_26" },
        { id: 5, name: '江津尖椒鸡', image: "https://so5.360tres.com/t0174cbd6f869143bdf.jpg" },
        { id: 6, name: '姜爆鸭', image: "https://img0.baidu.com/it/u=1407366085,2509115747&fm=253&fmt=auto&app=138&f=JPEG?w=410&h=360" },
        { id: 7, name: '重庆辣子鸡', image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fci.xiaohongshu.com%2F65b8dff6-1c63-35ab-bff5-f551e0a64b48%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fci.xiaohongshu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1714800345&t=8ae18b46df2c3b1e1dc8aec8462a37f8" },
        { id: 8, name: '毛血旺', image: "https://p4.itc.cn/images01/20220527/a0c4889cc07548beacb78e55db1507be.jpeg" },
        // ...其他菜品
      ],
    };
  },
};
</script>

<style scoped>
.dishes-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.dishes-container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
}

.dish-item {
  margin-bottom: 20px;
}

.image-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  text-align: center;
  margin: 10px;
}

.el-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

/* 响应式设计：在小屏幕上每行显示1个项目 */
@media (max-width: 768px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .image-container {
    height: auto;
  }

  .image-container img {
    width: auto;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}

/* 当屏幕宽度在768px到1023px之间时，假设这是横屏小屏幕，每行显示两个项目 */
@media (min-width: 768px) and (max-width: 1023px) {
  .el-col {
    flex: 0 0 50%; /* 将列宽设置为50%，以在小屏幕横屏时显示两个项目 */
    max-width: 50%;
  }

  /* 设置容器的高度，以确保每个项目高度一致 */
  .image-container {
    height: 200px;
    display: flex;
    justify-content: center; /* 图片水平居中 */
    align-items: center; /* 图片垂直居中 */
    overflow: hidden; /* 如果图片比容器大，隐藏多余部分 */
  }

  .image-container img {
    max-width: 100%; /* 图片宽度最大为容器宽度 */
    max-height: 100%; /* 图片高度最大为容器高度 */
    object-fit: cover; /* 保持图片的宽高比，同时覆盖整个容器 */
  }
}
</style>
