import request from '../utils/request'


// 用户名密码登录
export function login(email, password, captcha_id, code) {
    return request({
        url: "/user/pLogin",
        method: "post",
        data: {
            "email": email,
            "password": password,
            "captcha_id": captcha_id,
            "captcha_str64": code
        }
    })
}

// 图形验证码

export function captcha() {
    return request({
        url: "/captcha",
        method: "get",
    })
}


// 注册
export function register(email, password, re_password, code, invite_user_code) {
    return request({
        url: "/user/create",
        method: "post",
        data: {
            "email": email,
            "password": password,
            "re_password": re_password,
            "code": code,
            "invite_user_code": invite_user_code,
        }
    })
}

// 发送注册邮件
export function sendEmail(toEmail, type) {
    return request({
        url: "/sendValidateMail",
        method: "post",
        data: {
            "to": toEmail,
            "type": type
        }
    })
}

export function userDetail(email) {
    return request({
        url: "/user/detail",
        method: "post",
        data: {
            "email": email
        }
    })
}

export function pointUse(email, point) {
    return request({
        url: "/user/point/use",
        method: "post",
        data: {
            "email": email,
            "point": point
        }
    })
}
