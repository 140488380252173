<template>
  <div class="register-container">
    <el-card class="register-card">
      <router-link to="/" title="返回首页">
        <img src="https://t8.baidu.com/it/u=1473603170,4207808086&fm=193" alt="Logo" class="logo">
      </router-link>
      <h2 class="title">用户注册</h2>
      <el-form @submit.prevent="handleRegister" class="register-form">
        <el-form-item label="" prop="email">
          <el-input v-model="registerForm.email" placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input type="password" v-model="registerForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="" prop="re_password">
          <el-input type="password" v-model="registerForm.re_password" placeholder="请确认密码"></el-input>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input v-model="registerForm.code" placeholder="请输入邮箱验证码" class="code-input"></el-input>
          <el-button :disabled="!isValidEmail || !canGetCode" @click="getCode" :loading="getCodeLoading"
                     class="get-code-btn" type="primary">{{ getCodeButtonText }}
          </el-button>
        </el-form-item>
        <el-form-item label="" prop="code">
          <!-- 当 invitedUser 不为空时显示禁用的 input -->
          <!--          <el-input-->
          <!--              v-if="invitedUser"-->
          <!--              placeholder="邀请码"-->
          <!--              :value="displayText"-->
          <!--          ></el-input>-->
          <el-input
              placeholder="请输入邀请码(非必填)"
              v-model="invitedUserCode"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" native-type="submit" class="register-btn" :loading="loading" :disabled="disabled">
            {{ loading ? '注册中' : '注册' }}
          </el-button>
        </el-form-item>
        <div class="login-link">
          已有账号？
          <router-link to="/login">立即登录</router-link>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {register, sendEmail} from "@/api/user";
import {ElNotification} from 'element-plus';

export default {
  name: 'UserRegister',
  data() {
    return {
      registerForm: {
        email: '',
        password: '',
        re_password: '',
        code: ''
      },
      getCodeLoading: false,
      countdown: 0,
      loading: false,
      disabled: false,
      invitedUserCode: "",

    };
  },
  computed: {

    isValidEmail() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.registerForm.email);
    },
    canGetCode() {
      return this.isValidEmail && this.countdown === 0;
    },
    getCodeButtonText() {
      return this.countdown > 0 ? `${this.countdown} 秒` : '获取验证码';
    },
    displayText() {
      return `我的邀请人ID:${this.invitedUser}`;
    }
  },
  methods: {


    async handleRegister() {
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!this.registerForm.email) {
        this.$message.error('邮箱地址不能为空');
        return;
      }
      if (!emailPattern.test(this.registerForm.email)) {
        this.$message.error('请输入有效的邮箱地址');
        return;
      }
      if (!this.registerForm.password) {
        this.$message.error('密码不能为空');
        return;
      }
      if (!this.registerForm.re_password) {
        this.$message.error('确认密码不能为空');
        return;
      }
      if (this.registerForm.password !== this.registerForm.re_password) {
        this.$message.error('两次输入的密码不一致');
        return;
      }
      if (!this.registerForm.code) {
        this.$message.error('验证码不能为空');
        return;
      }
      // 验证通过后进入注册

      await register(this.registerForm.email, this.registerForm.password, this.registerForm.re_password, this.registerForm.code, this.invitedUserCode).then((resp) => {

        console.log(resp)
        if (resp.code === 0) {
          // this.$message.info("注册成功，即将在 3 秒后跳转到登录页面......");
          ElNotification({
            message: '注册成功，即将在 3 秒后跳转到登录页',
            type: 'success', // 这里将type设置为'success'以显示成功的通知
          });

          this.loading = false
          this.disabled = true

          setTimeout(() => {
            this.$router.push('/login');
          }, 3000); // 3000 毫秒后执行跳转
        } else {
          this.loading = false
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }
      })
    },

    async getCode() {
      this.getCodeLoading = true;
      await sendEmail(this.registerForm.email, "register").then((resp) => {
        this.getCodeLoading = false;
        if (resp.code === 0) {
          this.startCountdown();
          // this.$message.info("发送验证码成功，请注意查收邮件");
          ElNotification({
            message: '发送验证码成功，请注意查收邮件。',
            type: 'success', // 这里将type设置为'success'以显示成功的通知
          });

        } else {
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }
      })
    },


    // 验证码输入倒计时
    startCountdown() {
      this.countdown = 60;
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }
  }
  ,

  mounted() {
    const invitedUserCode = this.$route.query.invCode;
    if (invitedUserCode !== undefined && invitedUserCode !== null && invitedUserCode !== '') {
      this.invitedUserCode = invitedUserCode
    } else {
      this.invitedUserCode = ""
    }
  },
}
;
</script>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: antiquewhite;
}

.register-card {
  width: 360px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  width: 80px;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.register-form {
  text-align: left;
}

.register-btn {
  width: 100%;
}

.login-link {
  margin-top: 20px;
  font-size: 14px;
}

.login-link router-link {
  color: #409EFF;
  text-decoration: none;
}

.code-input {
  width: calc(100% - 100px); /* 调整验证码输入框宽度 */
}

.get-code-btn {
  width: 90px; /* 调整获取验证码按钮宽度 */
  margin-left: 10px;
}
</style>
