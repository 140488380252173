import request from '../utils/request'


// 优惠券列表
export function couponList(userId) {
    return request({
        url: "/coupon/list",
        method: "post",
        data: {
            "userId": userId,
        }
    })
}


export function monthlyCouponGet(userId) {
    return request({
        url: "/coupon/monthly/get",
        method: "post",
        data: {
            "userId": userId,
        }
    })
}


export function couponDetail(coupon_code) {
    return request({
        url: "/coupon/detail",
        method: "post",
        data: {
            "coupon_code": coupon_code,
        }
    })
}

export function couponUse(email, coupon_code, payment_amount, code) {

    return request({
        url: "/coupon/use",
        method: "post",
        data: {
            "email": email,
            "coupon_code": coupon_code,
            "payment_amount": payment_amount,
            "code": code
        }
    })
}

