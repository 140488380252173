<template>
  <div class="header-image">
    <img src="@/assets/宣传2.png" alt="迪拜椒点川菜">
    <div class="header-text">
      <h1>迪拜椒点川菜</h1>
      <p>地道重庆味江湖菜</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderImage',
};
</script>
<style scoped>
.header-image {
  position: relative;
  width: 100%; /* 宽度全屏 */
  height: 300px; /* 这里设置图片的固定高度 */
  overflow: hidden; /* 隐藏超出部分 */
  top: 52px;
  margin-bottom: 50px;
}

.header-image img {
  width: 100%; /* 图片宽度全屏 */
  height: 300px; /* 固定图片高度 */
  object-fit: cover; /* 覆盖整个容器，不留空隙，可能会裁剪图片 */
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px #000;
}
</style>
