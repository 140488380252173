<template>
  <div class="not-found-container">
    <div class="not-found-content">
      <h2>404</h2>
      <h5>哎呀~~,小主是不是迷路了呀。</h5>
      <button @click="$router.push('/')">椒点带您回家</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style scoped>
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.not-found-content {
  text-align: center;
  color: #fff;
}

.not-found-content h1 {
  font-size: 6em;
  margin: 0.5em 0;
}

.not-found-content p {
  font-size: 1.5em;
  color: #b9bbbe;
}

button {
  margin-top: 20px;
  padding: 10px 30px;
  font-size: 1em;
  color: #667eea;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #f8f9fa;
}
</style>
