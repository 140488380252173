<template>
  <div class="login-container">
    <el-card class="login-card">
      <router-link to="/" title="返回首页">
        <img src="https://t8.baidu.com/it/u=1473603170,4207808086&fm=193" alt="Logo" class="logo">
      </router-link>
      <h2 class="title">用户登录</h2>
      <el-form @submit.prevent="handleLogin" class="login-form">
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="loginForm.email" placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <!--        <el-form-item label="密码" prop="password">-->
        <!--          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="密码" prop="password">
          <el-input
              :type="showPassword ? 'text' : 'password'"
              v-model="loginForm.password"
              placeholder="请输入密码"
          >
            <template #append>
              <!--              <i :class="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']" @click.stop="togglePassword"></i>-->
              <i :class="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']" @click.stop="togglePassword"
                 style="cursor: pointer;"></i>

              <!--              <el-button @click.stop="togglePassword" icon="el-icon-view" type="text"></el-button>-->
            </template>
          </el-input>
        </el-form-item>

        <el-form-item label="验证" prop="captcha">
          <div class="captcha-container">
            <el-input v-model="loginForm.code" placeholder="请输入图形验证码" class="captcha-input"></el-input>
            <img class="captcha-img"
                 :src="captcha.captcha_str64"
                 @click="refreshCaptcha" alt=""/>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" class="login-btn" :loading="loading">登录</el-button>
        </el-form-item>
        <div class="link-container">没有账号？
          <router-link to="/register">立即注册</router-link>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {captcha, login} from "@/api/user";
import storage from "@/models/storage";
// import { ElNotification } from 'element-plus';


export default {
  name: 'UserLogin',
  data() {
    return {
      loading: false,
      showPassword: false,
      loginForm: {
        email: '',
        password: '',
        code: "",
      },
      captcha: {
        captcha_id: "",
        captcha_str64: ""
      }


    };
  },
  methods: {

    // 控制是否显示明文的密码
    togglePassword() {
      this.showPassword = !this.showPassword;
    },


    // 用户登录
    async handleLogin() {
      this.loading = true
      await login(this.loginForm.email, this.loginForm.password, this.captcha.captcha_id, this.loginForm.code).then((resp) => {
        this.loading = false;

        console.log(resp)
        if (resp.code === 0) {
          storage.set('loginUser', resp.data)
          this.$router.push('/')

        } else {
          this.refreshCaptcha()
          this.loading = false
          this.$message.error({
            message: resp.msg,
            center: true,
          })

        }
      }).catch(() => {
        this.loading = false
      });
    },

    // 刷新验证码
    async refreshCaptcha() {
      await captcha().then((resp) => {
        if (resp.code === 0) {
          this.captcha = resp.data
          console.log(this.captcha)
        } else {
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }
      })
    },


  }
  ,

// 页面加载的时候请求
  mounted() {
    this.refreshCaptcha()
  }
}
;
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: antiquewhite;
}

.login-card {
  width: 360px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.captcha-input {
  flex: 1;
  margin-right: 10px;
}

.captcha-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 80px;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.login-form {
  text-align: left;
}

.login-btn {
  width: 100%;
}

.captcha-img {
  width: 120px;
  height: 40px;
  cursor: pointer;
}

.link-container {
  margin-top: 20px;
  font-size: 14px;
}

.link-container router-link {
  color: #409EFF;
  text-decoration: none;
}

</style>
