<template>
  <div>
    <div v-if="showCouponForm">
      <h5>使用优惠券</h5>
      <el-form-item label="" prop="couponCode" class="coupon-form-item">
        <el-input
            type="text"
            v-model="couponCode"
            placeholder="请输入优惠券码"
            class="coupon-input"
        />

        <el-button
            type="primary"
            @click="validateCoupon"
            class="coupon-button"
        >
          验证优惠券
        </el-button>

      </el-form-item>

    </div>


    <el-dialog
        title="优惠券验证"
        v-model="showCouponModal"
        :width="dialogWidth"
        @close="handleClose"
    >
      <div class="dialog-content">
        <h5>优惠券类型:{{ couponInfo.coupon_type }}</h5>
        <h5 v-if="isCashType">优惠金额:{{ couponInfo.coupon_amount }} AED</h5>
        <h5 v-else>折扣力度:{{ couponInfo.coupon_rate }}折</h5>
        <h5>优惠券说明:{{ couponInfo.desc }}</h5>
        <div class="input-row">
          <el-input
              type="text"
              :value="couponInfo.email"
              disabled
              placeholder="请输入邮箱"
              class="input-half"
          ></el-input>
          <el-button
              type="primary"
              class="btn-quarter"
              @click="sendVerificationCode"
              :disabled="!canGetCode"
              :loading="getCodeLoading"
          >
            {{ getCodeButtonText }}
          </el-button>
        </div>
        <el-input
            type="text"
            v-model="verificationCode"
            placeholder="请输入验证码"
            class="input-full"
        ></el-input>

        <el-input
            type="number"
            v-model="paymentAmount"
            placeholder="请输入折扣后的消费金额"
            class="input-full"
            style="margin-top: 10px"
        ></el-input>
        <el-button
            type="primary"
            class="btn-full margin-top"
            @click="useCoupon"
        >
          使用优惠券
        </el-button>
      </div>
    </el-dialog>


    <div v-if="showPointsForm">
      <h5>使用积分</h5>
      <el-form-item label="" prop="couponCode" class="coupon-form-item">
        <el-input type="text" v-model="userEmail" placeholder="请输入用户邮箱" class="coupon-input"/>
        <el-button type="primary" @click="validateUserDetail" class="coupon-button">查看积分</el-button>
      </el-form-item>
      <el-dialog title="积分抵扣" v-model="showPointModal" :width="dialogWidth" @close="handleClose">
        <div class="dialog-content">
          <h4>当前可用积分:{{ userInfo.remaining_point }}</h4>
          <el-input type="text" style="margin-bottom: 10px" :value="userInfo.email" disabled placeholder="请输入邮箱"
                    class="input-half"></el-input>
          <el-input type="number" v-model="points" placeholder="请输入需要消费的积分"
                    class="input-half" style="margin-bottom: 10px"></el-input>

          <el-button type="primary" class="btn-full margin-top" @click="usePoints">使用积分</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import storage from "@/models/storage";
import {ElNotification, ElMessage} from 'element-plus';
import {couponDetail, couponUse} from "@/api/coupon";
import {pointUse, sendEmail, userDetail} from "@/api/user";

export default {
  name: 'adminPage',
  data() {
    return {
      couponInfo: {
        "coupon_code": "",
        "coupon_rate": "",
        "coupon_type": "",
        "desc": "",
        "email": "",
        "source": "",
        "coupon_amount": ""
      },
      userInfo: {
        "birthday": "",
        "email": "",
        "gender": "",
        "id": "",
        "invite_code": "",
        "invite_url": "",
        "invited_user_count": "",
        "remaining_point": "",
        "total_point": ""
      },
      userEmail: "",
      couponCode: '',
      paymentAmount: "",
      isCashType: false,
      verificationCode: '',
      points: "",
      showCouponForm: true,
      showCouponModal: false,
      showPointModal: false,
      showPointsForm: true,
      couponValidationMessage: '',
      countdown: 0,
      getCodeLoading: false,

      dialogWidth: '350px' // 调整对话框的宽度
    };
  },
  mounted() {
    if (storage.get("loginUser").role !== 2) {
      this.$router.push('/');
      ElNotification({
        title: '非管理员无法进入管理后台',
        type: 'error',
      });

    }


  },

  computed: {
    canGetCode() {
      return this.countdown === 0;
    },

    getCodeButtonText() {
      return this.countdown > 0 ? `${this.countdown} 秒` : '获取验证码';
    },
  },
  methods: {


    handleClose() {
      this.showCouponModal = false
      this.showPointModal = false
    },
    validateCoupon() {
      if (!this.couponCode) {
        ElMessage({
          message: '请输入优惠券码',
          type: 'warning'
        });
        return;
      }
      // 调用接口验证优惠券
      couponDetail(this.couponCode)
          .then(resp => {
            if (resp.code === 0) {
              this.couponInfo = resp.data;
              if (this.couponInfo.coupon_type === "现金优惠券") {
                this.isCashType = true
              }
              this.showCouponModal = true;
            } else {
              ElMessage({
                message: resp.msg,
                type: 'error'
              });
            }
          })
    },
    sendVerificationCode() {
      this.getCodeLoading = true;

      // 调用接口发送验证码
      sendEmail(this.couponInfo.email, "useCoupon")
          .then(response => {
            this.getCodeLoading = false;
            if (response.code === 0) {
              this.startCountdown();
              ElMessage({
                message: '验证码已发送',
                type: 'success'
              })
            } else {
              this.$message.error({
                message: response.msg,
                center: true,
              })
            }
          })

    },

    // 验证码输入倒计时
    startCountdown() {
      this.countdown = 60;
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    useCoupon() {
      if (!this.verificationCode) {
        ElMessage({
          message: '请输入验证码',
          type: 'warning'
        });
        return;
      }

      if (!this.paymentAmount) {
        ElMessage({
          message: '请输入消费金额',
          type: 'warning'
        });
        return;
      }

      // 将 payment_amount 转换为浮点数
      const amountAsFloat = parseFloat(this.paymentAmount);
      // 检查转换是否成功（避免 NaN）
      if (isNaN(amountAsFloat)) {
        ElMessage({
          message: '请输入正确的消费金额',
          type: 'warning'
        });
        return;
      }
      if (amountAsFloat <= 0) {
        ElMessage({
          message: '消费金额不能小于等于0',
          type: 'warning'
        });
        return;
      }

      // 调用接口使用优惠券
      couponUse(this.couponInfo.email, this.couponInfo.coupon_code, amountAsFloat, this.verificationCode)
          .then(response => {
            if (response.code === 0) {
              ElMessage({
                message: '优惠券使用成功',
                type: 'success'
              });
              // 清除表单或进行其他操作
              this.showCouponModal = false
              this.paymentAmount = '';
              this.verificationCode = '';
              this.couponCode = ""
              this.countdown = 0
            } else {
              ElMessage({
                message: response.msg,
                type: 'error'
              });
            }
          })

    },


    validateUserDetail() {
      // 判断是否输入了邮件
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!this.userEmail) {
        this.$message.error('邮箱地址不能为空');
        return;
      }
      if (!emailPattern.test(this.userEmail)) {
        this.$message.error('请输入有效的邮箱地址');
        return;
      }

      userDetail(this.userEmail).then((resp) => {
        if (resp.code === 0) {
          this.userInfo = resp.data
          this.showPointModal = true

        } else {
          this.$message.error({
            message: resp.msg,
            center: true,
          })
        }
      })
    },

    usePoints() {
      const pointsValue = parseFloat(this.points.trim());

      // 检查是否是数字且是正整数且大于0
      if (isNaN(pointsValue) || !Number.isInteger(pointsValue) || pointsValue <= 0) {
        ElMessage({
          message: '请输入有效的正整数作为积分数量',
          type: 'warning'
        });
        return; // 退出方法
      }

      this.points = parseInt(this.points)
      // 判断输入的amount是否超过当前用户的积分总数
      if (this.points > this.userInfo.remaining_point) {
        ElMessage({
          message: '积分不足，无法使用',
          type: 'warning'
        });
        return; // 退出方法
      }

      // 调用接口使用积分
      pointUse(this.userInfo.email, this.points)
          .then(response => {
            if (response.code === 0) {
              ElMessage({
                message: '积分使用成功',
                type: 'success'
              });
              this.points = 0;
              this.showPointModal = false
              this.userEmail = ""
            } else {
              ElMessage({
                message: response.msg,
                type: 'error'
              });
            }
          })

    }
  }
}
</script>

<style scoped>
.coupon-form-item {
  margin-bottom: 20px; /* 根据需要调整间距 */
}

.coupon-input {
  width: 200px; /* 根据需要调整宽度 */
  margin-right: 10px; /* 与按钮之间的间距 */
}

.coupon-button {
  padding: 8px 15px; /* 根据需要调整内边距 */
  border-radius: 4px; /* 圆角 */
  background-color: #409EFF; /* 背景色 */
  color: #fff; /* 文字颜色 */
}

.coupon-button:hover {
  background-color: #66b1ff; /* 鼠标悬停时的背景色 */
}

/* 验证提示样式 */
.el-tooltip__popper .popper__arrow {
  display: none; /* 隐藏提示框的箭头 */
}

.el-tooltip__popper .popper__content {
  padding: 8px 12px; /* 提示框的内边距 */
  background-color: #f5f7fa; /* 提示框的背景色 */
  color: #606266; /* 提示框的文字颜色 */
  border: 1px solid #ebeef5; /* 提示框的边框 */
  border-radius: 4px; /* 提示框的圆角 */
}


.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* 设置第一行元素与下一行元素之间的间距 */
  justify-content: space-between; /* 两端对齐，确保元素之间有间距 */
}

.input-half {
  flex: 1; /* 占据一半空间 */
  margin-right: 5px; /* 与按钮之间的间距 */
}

.btn-quarter {
  flex: 0 0 auto; /* 不占据额外空间，保持原始大小 */
  min-width: 100px; /* 设置按钮最小宽度 */
}

.input-full {
  width: 100%; /* 第二排输入框占据整行宽度 */
  box-sizing: border-box; /* 确保宽度包含padding和border */
}

.btn-full {
  width: 100%; /* 按钮占据整行宽度 */
  box-sizing: border-box; /* 确保宽度包含padding和border */
}

.margin-top {
  margin-top: 10px; /* 设置使用优惠券按钮的上边距 */
}
</style>