import {createRouter, createWebHistory} from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import Login from '@/views/UserLogin.vue';
import Register from '@/views/SignIn.vue';
import NotFound from '@/views/404.vue'
import admin from "@/views/Admin.vue";
import UsageInstructions from "@/views/UsageInstructions.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/help',
        name: 'help',
        component: UsageInstructions
    },
    {
        path: '/login',
        component: Login,
        name: "Login"
    },
    {
        path: '/register',
        component: Register,
        name: 'Register'
    },
    {
        path: '/404',
        component: NotFound,
        hidden: true
    },
    {
        path: '/admin',
        component: admin,
        hidden: true
    },

    // 添加你的其他路由规则...
    {
        path: '/:catchAll(.*)', // 使用自定义正则表达式参数
        name: 'NotFound',
        component: NotFound // 所有未匹配到的路径都会展示 NotFound 组件
    }
    // 其他路由...
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
