<template>
  <div id="app">
    <!-- Vue Router将在这里渲染当前路由对应的组件 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Global styles, if necessary, can go here */
</style>
